@import "src/assets/style/variables";
@import "src/assets/style/mixin";


.about-sec{
  margin-top: 120px;

  .about-main{
    margin-top: 50px;
    @include screen-max-width-991{
      margin-top: 0;
    }
      .about-cnt {
        @include screen-max-width-991{
          flex-direction: column;
          align-items: flex-start;
          gap: 30px;
          margin-top: 40px;
        }
        .about-info{
          width: 46%;
          @include screen-max-width-991{
            width: 100%;
          }
          p{
            font-size: 24px;
            line-height: 32px;
            color: #000000;
            font-weight: 500;
            @include screen-max-width-1399 {
              font-size: 20px;
              line-height: 28px;
            }
            @include screen-max-width-1200{
              font-size: 18px;
              line-height: 24px;
              font-weight: 400;
            }
          }

        }
        .about-image{
          position: relative;
          width: 50%;
          display: flex;
          @include screen-max-width-991{
            width: 100%;
          }
          &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
          }

          img{
            width: 100%;
          }
        }
      }

  }
}
