@import "./variables";
@import "./mixin";


.G-pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

.G-hidden {
  overflow: hidden;
}

.G-container {
  max-width: 1450px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

h2{
  font-size: 44px;
  text-transform: uppercase;
  color: #000000;
  line-height: 54px;
  @include screen-max-width-1200{
    font-size: 36px;
    line-height: 44px;
  }
  @include screen-max-width-991{
    font-size: 30px;
  }
  @include screen-max-width-576{
    font-size: 28px;
  }
}



.G-animation {
  opacity: 0;
  visibility: hidden;
  transition: 0.7s all;
  transform: translateY(20px);

  &.G-show-element {
    opacity: 1 !important;
    visibility: visible !important;
    transform: translateY(0);
  }

}
