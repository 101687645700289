// #region Flex

.G-flex {
  display: flex;
}

.G-inline-flex {
  display: inline-flex;
}

.G-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.G-justify-center {
  justify-content: center;
  display: flex;
}

.G-justify-between {
  justify-content: space-between;
  display: flex;
}

.G-justify-around {
  justify-content: space-around;
  display: flex;
}

.G-justify-end {
  justify-content: flex-end;
  display: flex;
}

.G-align-center {
  align-items: center;
  display: flex;
}

.G-align-start {
  align-items: flex-start;
  display: flex;
}

.G-align-end {
  align-items: flex-end;
  display: flex;
}

.G-flex-column {
  flex-direction: column;
  display: flex;
}

.G-flex-wrap {
  flex-wrap: wrap;
  display: flex;
}
.G-row {
  display: flex;
  flex-direction: row-reverse;
}

// #endregion
