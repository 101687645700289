@import "src/assets/style/variables";
@import "src/assets/style/mixin";



.projects-sec{
  margin-top: 120px;
  @include screen-max-width-991{
    margin-top: 80px;
  }
  .projects-cnt{
    max-width: 1920px;
    width: 100%;
    margin: 60px auto 0;
    @include screen-max-width-1399{
      margin: 30px auto 0;
    }
    @include screen-max-width-991{
      margin: 0;
    }
    img{
      transition: 0.3s;
      object-fit: cover;
      width: 600px;
      height: 400px;
      @include screen-max-width-991{
        height: 300px;
      }
      @include screen-max-width-767{
        height: 200px;
      }
      @include screen-max-width-576{
        height: 150px;
      }
    }
    .swiper{
      padding: 100px 0;
      @include screen-max-width-991{
        padding: 80px 0;
      }
      .swiper-slide-active{
        img{
          transform: scale(1.4);
        }

      }
      .projects-photo{
        display: flex;
      }
    }

  }
}
