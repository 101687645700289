@import "src/assets/style/variables";
@import "src/assets/style/mixin";

.services-sec{
  margin-top: 150px;
  @include screen-max-width-991{
    margin-top: 80px;
  }
}
.services-cnt{
  margin-top: 40px;
  gap: 30px;
  @include screen-max-width-1200{
    gap: 20px;
  }
  @include screen-max-width-991{
    margin-top: 20px;
  }
  .services-box{
    width: calc(33.333% - 20px);
    border-radius: 10px;
    padding: 30px 20px;
    background: #ffffff;
    box-shadow: 0 1px 5px rgba(0,0,0,0.5);
    transition: 0.5s;
    position: relative;
    cursor: pointer;
    @include screen-max-width-1200{
      padding: 20px;
      width: calc(50% - 10px);
    }
    @include screen-max-width-576{
      width: 100%;
      padding: 30px 15px;
    }
    &:before{
      content: "";
      bottom: 0;
      left: 0;
      width: 0;
      height: 4px;
      background: #000000;
      position: absolute;
      transition: 0.5s;
    }
    &:after{
      content: "";
      bottom: 0;
      right: 0;
      width: 0;
      height: 4px;
      background: #000000;
      position: absolute;
      transition: 0.5s;

    }
    &:hover{
      border-radius: 0;
      transform: translateY(-10px);
      &:before{
        width: 100%;
      }
      &:after{
        width: 100%;
      }
    }
    h3{
      font-size: 28px;
      line-height: 36px;
      color: #000000;
      font-weight: 500;
      display: flex;
      align-items: center;
      @include screen-max-width-1200{
        font-size: 24px;
        line-height: 32px;
      }
      @include screen-max-width-991{
        font-size: 20px;
        line-height: 28px;
      }
    }
    p{
      margin-top: 15px;
      font-size: 20px;
      line-height: 28px;
      color: #000000;
      font-weight: 400;
      border-top: 1px solid;
      padding-top: 12px;
      @include screen-max-width-1200{
        font-size: 18px;
      }
      @include screen-max-width-991{
        font-size: 16px;
      }
    }
  }
}
