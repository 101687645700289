// colors

$white: #ffffff;
$black: #000000;
$blue: #0080ff;
//page withs

$max-width-1399: 1399px;
$max-width-1200: 1200px;
$max-width-991: 991px;
$max-width-767: 767px;
$max-width-576: 576px;

// transitions
$transition: 0.3s;
