@import 'src/assets/style/mixin';
.map-sec{
  margin-top: 120px;
  @include screen-max-width-991{
    margin-top: 80px;
  }
  @include screen-max-width-767{
    margin-top: 30px;
  }

  .map{
    width: 100%;
    height: 600px;
    @include screen-max-width-767{
      height: 400px;
    }
    iframe{
      width: 100%;
      height: 100%;
    }
  }
}
