@import "src/assets/style/variables";
@import "src/assets/style/mixin";

.project-title{
  margin-top: 150px;
  @include screen-max-width-991{
    margin-top: 80px;
  }
}

.projects-list{
  width: 100%;
  margin: 60px auto 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  @include screen-max-width-1399{
    margin: 30px auto 0;
  }
  @include screen-max-width-991{
    grid-template-columns: 1fr 1fr;
  }
  @include screen-max-width-576{
    grid-template-columns: 1fr;
  }
}



.project-item{
  overflow: hidden;
  border-radius: 12px;
  width: 100%;

  .project-img{
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    overflow: hidden;
    border-radius: 12px;
  }
  .project-info{
    padding: 10px;
  }

  .project-img-slider {
    position: relative;

    .swiper-arrows-item {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      width: 40px;
      height: 40px;
      color: white;
      background-color: rgba(0,0,0,0.4);
      border-radius: 50%;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;


      &.swiper-button-prev{
        left: 20px;
      }

      &.swiper-button-next{
        right: 20px;
      }
    }
  }
  &:hover{
    .swiper-arrows-item{
      opacity: 1;
      visibility: visible;
    }
  }
}
