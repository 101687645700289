@import "src/assets/style/variables";
@import "src/assets/style/mixin";


.banner-sec{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
  }
  .banner-main{
    padding: 100px 0 100px;
    height: 100vh;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    position: relative;
      h1{
        font-size: 50px;
        color: #ffffff;
        font-weight: 700;
        text-transform: uppercase;
        max-width: 1100px;
        width: 100%;
        @include screen-max-width-1200{
          font-size: 40px;
          max-width: 805px;
        }
        @include screen-max-width-991{
          font-size: 34px;
        }
        @include screen-max-width-576{
          font-size: 28px;
        }
      }
      p{
        margin-top: 40px;
        font-size: 28px;
        color: #ffffff;
        line-height: 38px;
        max-width: 900px;
        width: 100%;
        @include screen-max-width-1200{
          font-size: 22px;
          line-height: 32px;
          max-width: 800px;
        }
        @include screen-max-width-991{
          font-size: 20px;
          line-height: 28px;
        }
    }
  }
}
