@import "src/assets/style/variables";
@import "src/assets/style/mixin";


footer{
  background: #000000;
  .footer-main{
    padding: 20px 0 20px;
    padding-top: 80px;
    @include screen-max-width-576{
      margin-top: 60px;
      flex-direction: column;
      gap: 30px;
    }
    .footer-logo{
      display: flex;
      width: 70px;
    }

    .footer-contact{
      gap: 30px;
      @include screen-max-width-576{
        flex-direction: column;
        gap: 20px;
      }
      a{
        color: #ffffff;
        font-size: 18px;
        transition: 0.3s;
        border-bottom: 1px solid transparent;
        display: flex;
        align-items: center;
        gap: 8px;
        span{
          border-bottom: 1px solid transparent;
          transition: 0.3s;

          &:hover{
            border-bottom: 1px solid #ffffff;
          }
        }
      }
    }
  }
  .footer-down{
    padding-bottom: 20px;
    border-top: 1px solid #ffffff;
    padding-top: 20px;
    p{
      color: #ffffff;
      font-size: 16px;
      text-align: center;
    }
  }
}
